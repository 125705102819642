<template>
    <v-container>
        <v-form :action="url" method="post" enctype="multipart/form-data" v-model="isFormValid" ref="form">
            <input type="hidden" name="key" ref="key" :value="key" />
            <input type="hidden" name="acl" value="bucket-owner-full-control" />
            <v-row justify="center">
                <v-col cols="8">
                    <v-file-input outlined chips
                        name="file" ref="file"
                        :rules="rules"
                        label="Chest X-Ray JPEG, PNG, or DICOM file"
                        accept=".dcm, image/png, image/jpeg" />
                    <!-- <input type="file" name="file" ref="file" accept="image/png, image/jpeg, application/dicom" @change="onFileSelected" /> -->
                </v-col>
                <v-col cols="2">
                    <UploadConfirmation v-on:confirmed="onConfirmed" :isFormValid="isFormValid" />
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
//import uuid from 'vue-uuid';
import UploadConfirmation from './UploadConfirmation.vue';
//import VuejsDialog from 'vuejs-dialog';

export default {
    name: 'Upload',
    components: {
        UploadConfirmation 
    },
    props: {
        url: String
    },
    data: function() {
        return {
            extension: "",
            key: "",
            uuid: "",
            isFormValid: false,
            rules: [
                file => this.onFileSelected(file)
            ]
        }
    },
    watch: {
        extension: function() {
            this.key = this.uuid + "." + this.extension;
        },
        uuid: function() {
            this.key = this.uuid + "." + this.extension;
        }
    },
    methods: {
        onConfirmed: function() {
            this.uuid = this.$uuid.v4();
            this.key = this.key = this.uuid + "." + this.extension;
            this.$refs["key"].value = this.key;

            let form = this.$refs["form"];
            console.log(form);
            form.$el.submit();
            this.$emit('uploaded', {'uuid':this.uuid});
        },
        onFileSelected: function(file) {
            //let fileName = event?.target?.value;
            console.log(typeof(file), file);
            if (typeof(file) !== "object" || file === null) return "Please select a file";

            let extension =  /(?:\.([^.]+))?$/.exec(file.name)[1];
            let validExtensions = ["jpg", "jpeg", "png", "dcm"];
            if (validExtensions.find(ext => ext === extension)) {
                this.extension = extension;
                return true;
            } else {
                this.extension = "";
                return "Invalid file type";
            }
        }
    }
}
</script>