<template>
    <v-container>
        <v-container v-if="loading">
            <GridLoader color="#00274c" value="processing" size="20px" />
            <br />
            <span>Processing...{{percentComplete}}%</span>
        </v-container>
        <v-container v-else>
            <img :src="src" />
            <br />
            <v-btn color="primary" @click="saveImage">Download Results</v-btn>
        </v-container>
    </v-container>
</template>

<script>
import GridLoader from 'vue-spinner/src/GridLoader.vue';
import { saveAs } from 'file-saver';

export default {
    name: 'AsyncImage',
    components: {
        GridLoader
    },
    props: {
        src: String
    },
    data: function() {
        return {
            loading: true,
            retries: 0
        }
    },
    computed: {
        percentComplete: function () { return Math.min(95, this.retries * 5); }
    },
    watch: {
        src: function() {
            this.retries = 0;
            this.loading = true;
            this.checkImageExists();
        }
    },
    methods: {
        checkImageExists: function () {
            console.log("checking...")
            let _this = this;
            let maxRetries = 60;
            let retry = function () {
                if (_this.retries++ < maxRetries) {
                    console.log("Results not ready. Retrying in 3 seconds...");
                    setTimeout(function() { _this.checkImageExists(); }, 5000);
                }
            };

            let request = new XMLHttpRequest();
            request.ontimeout = retry;
            request.onerror = retry;
            request.onload = function () {
                if (request.status === 200) {
                    _this.loading = false;
                } else {
                    retry();
                }
            };
            request.open('GET', this.src, false);
            request.send(null);
        },
        saveImage: function () {
            saveAs(this.src, 'ards_detection_results.jpeg');
        }
    },
    mounted () {
        this.retries = 0;
        this.loading = true;
        this.checkImageExists();
    }
}
</script>

<style scoped>
.v-spinner {
    display: inline-block;
}
</style>