<template>
    <v-app>
        <Navbar title="ARDS Detection" />
        <v-main>
            <v-container>
                <h1>ARDS Detection Tool</h1>
                <p>Our tool will accept the user’s image upload (JPG, PNG, or DICOM), preprocess the image for use with our ARDS detection software, apply a machine learning model (<a href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(21)00056-X/fulltext" target="_blank" rel="noopener noreferrer">see details here</a>), and apply the Grad-CAM tool to localize important areas of the chest x-ray. During this process, we will generate a universally unique identifier (UUID) for the original image, the preprocessed image, and the Grad-CAM generated image. These images will be stored for 15 minutes and then deleted from our server. The images will be stored for 15 minutes so they can be properly displayed on the website.</p>

                <h3>Usage agreement</h3>
                <p>Please be aware that our research-grade tool is NOT approved for use with Protected Health Information (PHI). It is the user’s responsibility to ensure that the image that is uploaded has been de-identified. By using this tool you agree to not upload any PHI to our website. This tool is being made available to support ongoing ARDS research efforts only. Under no circumstances should the tool be used to support patient care activities or treatment decisions.</p>
            </v-container>
            <v-container>
                <h1>Upload Your Image</h1>
                <Upload url="https://ards-test.s3.amazonaws.com/" v-on:uploaded="onUploaded" />
            </v-container>
            <v-container>
                <AsyncImage v-if="imageUrl" :src="imageUrl" />
            </v-container>
        </v-main>
        <v-footer>
            <v-col class="text-center" cols="12">
                <h6>This work was supported in part by a grant from the Department of Defense: W81XWH2010496</h6>
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Upload from './components/Upload.vue'
import AsyncImage from './components/AsyncImage.vue'

export default {
    name: 'App',
    components: {
        Navbar,
        Upload,
        AsyncImage
    },
    data: function () {
        return {
            imageUrl: null
        };
    },
    methods: {
        onUploaded: function (event) {
            this.imageUrl = "https://ards-output.s3.amazonaws.com/" + event.uuid + ".jpeg";
        }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}
body {
  margin:0
}
.content {
  text-align: center;
  width: 100%;
}
</style>
