import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import UUID from "vue-uuid";
 
//import VuejsDialog from 'vuejs-dialog';

import '../node_modules/vuetify/dist/vuetify.min.css'
//import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.config.productionTip = false
//Vue.use(VuejsDialog)
Vue.use(UUID);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
