<template>
    <v-app-bar app dark color="#00274c">
        <v-toolbar-side-icon>
            <a href="https://mcircc.umich.edu/">
                <v-img
                    src="@/assets/Weil-Text-Treatment-Small-Crop.png"
                    class="mx-2"
                    contain max-height="50px" />
            </a>
        </v-toolbar-side-icon>
        <v-divider class="mx-4" vertical dark />
        <v-toolbar-title class="mx-2"><h2 style="font-weight:lighter">{{title}}</h2></v-toolbar-title>
    </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    title: String
  }
}
</script>

<style scoped>
.theme--dark.v-divider {
    border-color: rgba(255, 255, 255, 1);
}
</style>