<template>
    <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn text large slot="activator" class="primary" v-bind="attrs" v-on="on" :disabled="!isFormValid">Upload</v-btn>
        </template>
        <v-card>
            <v-card-title><h2>ARDS Detection Tool Terms of Use</h2></v-card-title>
            <v-card-text class="text-left">
                <!-- <p>Please be aware that our research-grade tool is NOT approved for use with Protected Health Information (PHI). It is the user’s responsibility to ensure that the image that is uploaded has been de-identified. By using this tool you agree to not upload any PHI to our website. This tool is being made available to support ongoing ARDS research efforts only. Under no circumstances should the tool be used to support patient care activities or treatment decisions.</p>
                
                <h4>How it works:</h4>
                <p>Our tool will accept the user’s image upload (JPG, PNG, or DICOM), preprocess the image for use with our ARDS detection software, apply a machine learning model (see details here: link to the publication), and apply the Grad-CAM tool to localize important areas of the chest x-ray. During this process, we will generate a universally unique identifier (UUID) for the original image, the preprocessed image, and the Grad-CAM generated image. These images will be stored for 15 minutes and then deleted from our server. The images will be stored for 15 minutes so they can be properly displayed on the website.</p>

                <h4>Approval:</h4> -->
                <p>I agree that I am not uploading any PHI data and that my uploaded image will be used by the ARDS Detect analytic to determine if a patient’s chest x-ray is consistent with ARDS. I understand that this tool is made available to support ongoing research efforts in ARDS and it should under no circumstances be used to support patient care.</p>
            </v-card-text>
            <v-container>
                <v-checkbox v-model="confirmation" label="The image I am uploading does not contain PHI"></v-checkbox>
            </v-container>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn color="primary" @click="dialog = false">Cancel</v-btn>
                <v-spacer />
                <v-btn color="primary" outlined @click="onConfirmed" :disabled="!confirmation">Upload</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isFormValid: Boolean
    },
    data () {
      return {
        dialog: false,
        notifications: false,
        confirmation: false
      }
    },
    methods: {
        onConfirmed: function() {
            if (this.confirmation === true) {
                this.$emit("confirmed");
                this.dialog = false;
                this.confirmation = false;
            }
        }
    }
}
</script>